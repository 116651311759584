import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "I Gave up on RSS",
  "date": "2015-07-15T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As I’m typing this, I currently have 243 unread items sitting in my RSS app. It was too much. It’s hard to pinpoint a specific time when it went from a good way to stay on top of news to feeling like a burden, but I feel like I spend way more time managing it than simply reading lately.`}</p>
    <h3>{`Stream vs Inbox`}</h3>
    <p>{`I think one of the biggest problems with RSS is that it was built following the inbox model. New items are added to your inbox over time and must be manually marked as read to be cleared, just like email. The inbox model is great for things you don’t want to miss, or when there is a very low volume of content. The problem here is that we have way more content sources than ever before.`}</p>
    <p>{`In that case, the stream approach works way better. The stream just flows whether you’re looking at it or not and surfaces the most relevant information at the moment (usually based on time or your social graph). That means you can sometimes miss things, but you don’t have to manage anything. There’s no anxiety that builds up over time.`}</p>
    <p>{`That’s something to think about when you’re designing apps or service. Is it an inbox or is it a stream? Both can be appropriate depending on what you’re designing, but they have a huge impact on the rest of the decisions you make after that.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      